import React from 'react'

export default function Trash({
    fill
}) {
    return (
        <svg width={13} height={16} viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3237 14.4929C10.3155 14.6897 10.1405 14.8571 9.94236 14.8571H2.62809C2.43006 14.8571 2.25492 14.6892 2.24674 14.4929L1.82759 4.43335C1.81445 4.11804 1.54818 3.87307 1.23287 3.88621C0.917548 3.89935 0.672583 4.16561 0.685721 4.48093L1.10487 14.5405C1.13856 15.349 1.81748 16 2.62809 16H9.94236C10.7525 16 11.4318 15.3501 11.4656 14.5405L11.8847 4.48093C11.8979 4.16561 11.6529 3.89935 11.3376 3.88621C11.0223 3.87307 10.756 4.11804 10.7429 4.43335L10.3237 14.4929ZM5.82808 13.1429C5.82808 13.3953 6.03275 13.6 6.28523 13.6C6.5377 13.6 6.74237 13.3953 6.74237 13.1429V5.37143C6.74237 5.11896 6.5377 4.91429 6.28523 4.91429C6.03275 4.91429 5.82808 5.11896 5.82808 5.37143V13.1429ZM3.54257 13.1563C3.54999 13.4087 3.76059 13.6072 4.01295 13.5998C4.26532 13.5924 4.46388 13.3818 4.45646 13.1294L4.22789 5.35799C4.22046 5.10563 4.00986 4.90706 3.7575 4.91448C3.50514 4.92191 3.30657 5.1325 3.314 5.38487L3.54257 13.1563ZM8.114 13.1294C8.10657 13.3818 8.30514 13.5924 8.5575 13.5998C8.80987 13.6072 9.02046 13.4087 9.02789 13.1563L9.25646 5.38487C9.26388 5.1325 9.06532 4.92191 8.81295 4.91448C8.56059 4.90706 8.34999 5.10563 8.34257 5.35799L8.114 13.1294ZM9.09993 1.94286L8.88244 1.18164C8.69123 0.512419 8.01168 0 7.31574 0H5.25471C4.55886 0 3.87917 0.512603 3.68801 1.18164L3.47052 1.94286H0.57094C0.255349 1.94286 -0.000488281 2.19869 -0.000488281 2.51429C-0.000488281 2.82988 0.255349 3.08571 0.57094 3.08571H11.9995C12.3151 3.08571 12.5709 2.82988 12.5709 2.51429C12.5709 2.19869 12.3151 1.94286 11.9995 1.94286H9.09993Z" fill={fill || "white"} fillOpacity="0.9" />
        </svg>
    )
}