import React from 'react'

export default function Hamburger({
    user,
    userColors
}) {
    return (
        <svg width="19.2" height="14" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.3999 9.19999L1.5999 9.19999C0.92771 9.19999 0.399902 8.67218 0.399902 7.99999C0.399902 7.3278 0.92771 6.79999 1.5999 6.79999L18.3999 6.79999C19.0721 6.79999 19.5999 7.3278 19.5999 7.99999C19.5999 8.67218 19.0721 9.19999 18.3999 9.19999ZM18.3999 3.19999L1.5999 3.19999C0.92771 3.19999 0.399902 2.67218 0.399902 1.99999C0.399902 1.3278 0.92771 0.799988 1.5999 0.799988L18.3999 0.799988C19.0721 0.799988 19.5999 1.3278 19.5999 1.99999C19.5999 2.67218 19.0721 3.19999 18.3999 3.19999ZM1.5999 12.8L18.3999 12.8C19.0721 12.8 19.5999 13.3278 19.5999 14C19.5999 14.6722 19.0721 15.2 18.3999 15.2L1.5999 15.2C0.92771 15.2 0.399902 14.6722 0.399902 14C0.399902 13.3278 0.92771 12.8 1.5999 12.8Z" fill={user && user.id && userColors[user.id]} />
        </svg>
    )
}
