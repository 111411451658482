// User
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const SET_USER_DISPLAYNAME = "SET_USER_DISPLAYNAME";
export const SET_USER_COLOR = "SET_USER_COLOR";

// Display
export const SET_IS_SHOWING_SIGNIN_DIALOG = "SET_IS_SHOWING_SIGNIN_DIALOG";
export const SET_REDIRECT_AFTER_SIGN_IN = "SET_REDIRECT_AFTER_SIGN_IN";
export const SET_SIGNUP_DISPLAYNAME = "SET_SIGNUP_DISPLAYNAME";
export const SET_SELECTED_LAYER_ID = "SET_SELECTED_LAYER_ID";
export const SET_IS_SHOWING_LAYER_SETTINGS = "SET_IS_SHOWING_LAYER_SETTINGS";
export const SET_IS_USING_JITSI = "SET_IS_USING_JITSI";
export const SET_IS_SHOWING_DELETE_ROUND_DIALOG = "SET_IS_SHOWING_DELETE_ROUND_DIALOG";
export const SET_IS_SHOWING_RENAME_DIALOG = "SET_IS_SHOWING_RENAME_DIALOG";
export const SET_IS_SHOWING_SHARE_DIALOG = "SET_IS_SHOWING_SHARE_DIALOG";
export const SET_DISABLE_KEY_LISTENER = "SET_DISABLE_KEY_LISTENER";
export const SET_SELECTED_ROUND_ID = "SET_SELECTED_ROUND_ID";
export const SET_IS_SHOWING_ORIENTATION_DIALOG = "SET_IS_SHOWING_ORIENTATION_DIALOG";
export const SET_IS_RECORDING_SEQUENCE = "SET_IS_RECORDING_SEQUENCE";
export const SET_IS_PLAYING_SEQUENCE = "SET_IS_PLAYING_SEQUENCE";
export const SET_CURRENT_SEQUENCE_PATTERN = "SET_CURRENT_SEQUENCE_PATTERN";


// Rounds
export const SET_ROUNDS = "SET_ROUNDS";

// Round
export const SET_ROUND = "SET_ROUND";
export const UPDATE_LAYERS = "UPDATE_LAYERS";
export const TOGGLE_STEP = "TOGGLE_STEP";
export const ADD_LAYER = "ADD_LAYER";
export const SET_STEP_PROBABILITY = "SET_STEP_PROBABILITY";
export const SET_STEP_VELOCITY = "SET_STEP_VELOCITY";
export const SET_STEP_NOTE = "SET_STEP_NOTE";
export const ADD_STEP = "ADD_STEP";
export const REMOVE_STEP = "REMOVE_STEP";
export const SET_LAYER_NAME = "SET_LAYER_NAME";
export const SET_LAYER_GAIN = "SET_LAYER_GAIN";
export const SET_LAYER_MUTE = "SET_LAYER_MUTE";
export const SET_LAYER_PREVIEW = "SET_LAYER_PREVIEW";
export const UPDATE_LAYER_INSTRUMENT = "UPDATE_LAYER_INSTRUMENT";
export const ADD_ROUND_LAYERS = "ADD_ROUND_LAYERS";
export const REMOVE_LAYER = "REMOVE_LAYER";
export const SET_ROUND_NAME = "SET_ROUND_NAME";
export const SET_ROUND_BPM = "SET_ROUND_BPM";
export const SET_ROUND_SWING = "SET_ROUND_SWING";
export const TOGGLE_LAYER = "TOGGLE_LAYER";
export const SET_LAYER_STEPS = "SET_LAYER_STEPS";
export const SET_ROUND_ID = "SET_ROUND_ID";
export const UPDATE_STEP = "UPDATE_STEP";
export const SET_LAYER_TYPE = "SET_LAYER_TYPE";
export const UPDATE_LAYER_AUTOMATION_FX_ID = "UPDATE_LAYER_AUTOMATION_FX_ID";
export const SET_USER_BUS_FX_OVERRIDE = "SET_USER_BUS_FX_OVERRIDE";
export const ADD_USERBUS = "ADD_USERBUS";
export const SET_USER_BUS_FX = "SET_USER_BUS_FX";
export const SAVE_USER_PATTERN = "SAVE_USER_PATTERN";
export const SET_IS_PLAYING = "SET_IS_PLAYING";
export const SET_ROUND_SHORTLINK = "SET_ROUND_SHORTLINK";
export const SET_ROUND_CURRENT_USERS = "SET_ROUND_CURRENT_USERS";
export const SET_LAYER_TIME_OFFSET = "SET_LAYER_TIME_OFFSET";
export const SET_LAYER_PERCENT_OFFSET = "SET_LAYER_PERCENT_OFFSET";
export const UPDATE_LAYER = "UPDATE_LAYER";
export const SET_USER_PATTERN_SEQUENCE = "SET_USER_PATTERN_SEQUENCE";




// Users
export const SET_USERS = "SET_USERS";

