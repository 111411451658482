import React from 'react'

export default function Muted({
    fill
}) {
    return (
        <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.3088 0.58485C8.04509 0.455108 7.72692 0.475126 7.48422 0.63823L2.62311 3.89663H1.7923C0.802791 3.89663 0 4.66173 0 5.60479V8.39534C0 9.3384 0.802791 10.1035 1.7923 10.1035H2.62311L7.48422 13.3619C7.72772 13.525 8.04509 13.545 8.30958 13.4145C8.57329 13.2841 8.73897 13.0246 8.73897 12.7414V1.25875C8.73897 0.975542 8.57252 0.715334 8.3088 0.58485ZM7.14584 11.2802L3.47959 8.82253C3.32945 8.67128 3.12175 8.58602 2.90394 8.58528H1.79232C1.68263 8.58528 1.59317 8.50002 1.59317 8.39549V5.60493C1.59317 5.50039 1.68263 5.41513 1.79232 5.41513H2.90394C3.12177 5.41439 3.32946 5.32913 3.47959 5.17789L7.14584 2.72023V11.2802Z" fill={fill || "white"} fillOpacity="0.9" />
            <path d="M10.9263 4.30424C10.5933 4.63726 10.5933 5.1772 10.9263 5.51023L12.1323 6.71621L10.9263 7.92219C10.5933 8.25522 10.5933 8.79516 10.9263 9.12818C11.2593 9.46121 11.7993 9.46121 12.1323 9.12818L13.3383 7.9222L14.5443 9.12821C14.8773 9.46123 15.4173 9.46123 15.7503 9.12821C16.0833 8.79518 16.0833 8.25524 15.7503 7.92221L14.5443 6.71621L15.7503 5.51021C16.0833 5.17718 16.0833 4.63724 15.7503 4.30422C15.4173 3.97119 14.8773 3.97119 14.5443 4.30422L13.3383 5.51022L12.1323 4.30424C11.7993 3.97121 11.2593 3.97121 10.9263 4.30424Z" fill={fill || "white"} fillOpacity="0.9" />
        </svg>
    )
}