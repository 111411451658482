import React from 'react'

export default function Erasor({
    fill
}) {
    return (
        <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.426474 10.5044C-0.141507 9.93647 -0.141507 9.01562 0.426474 8.44765L8.44833 0.425976C9.01631 -0.141992 9.93719 -0.141992 10.5052 0.425976L14.8426 4.76329C15.4106 5.33125 15.4106 6.25211 14.8426 6.82008L9.29575 12.3668L15.2304 12.3668C15.6559 12.3668 16.0009 12.7117 16.0009 13.1372C16.0009 13.5627 15.6559 13.9077 15.2304 13.9077L7.30514 13.9077C7.25169 13.9136 7.19769 13.9167 7.14338 13.9167L4.44122 13.9167C4.05549 13.9167 3.68556 13.7634 3.4128 13.4907L0.426474 10.5044ZM7.11657 12.3668L4.60582 12.3668C4.56561 12.3668 4.52611 12.3699 4.48756 12.3758H4.47705L1.57723 9.47604L4.30121 6.75213L8.51628 10.9671L7.11657 12.3668ZM13.6918 5.79168L9.60587 9.87754L5.3908 5.66257L9.47675 1.57671L13.6918 5.79168Z" fill={fill || "white"} fillOpacity="0.9" />
        </svg>
    )
}